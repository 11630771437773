export const useSeoFormatting = () => {
  const { t } = useI18n({
    useScope: 'global',
  });
  const formatPageTitle = (title: string) => {
    return `${t('appName')}: ${title}`;
  };

  return {
    formatPageTitle,
  };
};
